@import 'toolkit';

html {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: color(identityBlack);
  background: #f4f4f5;
}

body {
  min-height: 100%;
  font-family: $fontMain;
  overflow: hidden;
  background: #f4f4f5;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }

  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.zwe-grid {
  display: grid;
}

.zwe-grid--1fr-1fr {
  grid-template-columns: 1fr 1fr;
}
