@use 'mat';
@import 'normalize.css/normalize';
@import './variables';

/** material and base theme from zef lib */
@include mat.core();
@import 'theme';
@include mat.all-legacy-component-themes($appTheme);

/** base local */
@import './base/page';
@import './base/typography';

/** base from zef lib **/
@import 'base/material';
@import 'base/zef';

/** base components from zef lib **/
@import 'components/c-inline-list';
@import 'components/c-button';
@import 'components/c-dialog';
@import 'components/c-internal-link';

/** base utils from zef lib **/
@import 'util/text';
@import 'util/color';
@import 'util/background';
@import 'util/position';
@import 'util/global';
